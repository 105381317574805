<template>
    <UFormGroup :label="label" :error="errorMessage" :help="help">
      <UInput size="xl" v-model="inputValue" :placeholder="placeholder" :type="type" :trailing-icon="errorMessage ? 'i-heroicons-exclamation-triangle-20-solid' : undefined"/>
    </UFormGroup>
</template>

<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'text'
  },
  label: {
    type: String,
    required: true,
  },
  help: {
    type: String|null,
    default: null
  },
  placeholder: {
    type: String,
    default: ''
  },
  prefix: {
    type: [String, Boolean],
    default: false
  },
})

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
} = useField(name, undefined);
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
</style>